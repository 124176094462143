<template>
  <v-app>
    <v-data-table :headers="headers" :items="supplierstocktemplates" :footer-props="{
        showFirstLastPage: true,
        showCurrentPage: true,
        itemsPerPageOptions: [50, 100, 150, -1],
        itemsPerPageAllText: 'Alle',
        itemsPerPageText: 'Datensätze pro Seite',
        pageText: '{0}-{1} von {2}'
      }">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Bestandsvorlagen des Lieferantes {{ editdata.supplierName }} </v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="success" class="mx-2" fab dark small v-bind="attrs" v-on="on">
                <v-icon dark>
                  mdi-plus
                </v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-app-bar dark color="success">
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>
              </v-app-bar>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field v-model="editdata.supplierName" label="Lieferantenname*" disabled></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-select v-model="editdata.supplierType" :items="$func.getSupplierTypeOptions()"
                        label="Lieferantentype*" item-text="text" item-value="value" disabled></v-select>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field v-model="editedItem.templateName" label="Vorlagenname*" :rules="rules.name"
                        required></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field v-model="editedItem.supplierNumber" label="Lieferantennummer*" :rules="rules.number"
                        type="number" required></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field v-model="editedItem.rowCountDeviationPercent" label="Abweichung der Artikelanzahl"
                        append-outer-icon="mdi-percent" :rules="rules.percent" type="number" min=0></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field v-model="editedItem.import_timeout"
                        label="Zeitüberschreitung des Imports (in Minuten)" prepend-icon="mdi-clock-time-seven-outline"
                        type="number" min=0 hint="Für keine Zeitüberschreitung 0 eingeben"
                        persistent-hint></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field label="Beschreibung" v-model="editedItem.description"
                        hide-details="auto"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-switch v-model="editedItem.lastAction"
                        :label="`${editedItem.lastAction > 0 ? 'Bestandsvorlage aktiviert' : 'Bestandsvorlage deaktiviert'}`"
                        color="green"></v-switch>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" text @click="close">
                  Abbrechen
                </v-btn>
                <v-btn color="primary" @click="save">
                  Speichern
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">Sind Sie sicher, dass Sie diesen Lieferant löschen möchten?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete">Abbrechen</v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">Ja</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.templateName="{ item }">
        {{ item.templateName }}
      </template>
      <template v-slot:item.supplierNumber="{ item }">
        {{ item.supplierNumber }}
      </template>
      <template v-slot:item.rowCountDeviationPercent="{ item }">
        {{ item.rowCountDeviationPercent+' %' }}
      </template>
      <template v-slot:item.import_timeout="{ item }">
        {{ item.import_timeout > 0 ? item.import_timeout : 'Keine' }}
      </template>
      <template v-slot:item.imported_at="{ item }">
        {{ item.imported_at }}
      </template>
      <template v-slot:item.lastAction="{ item }">
        <v-switch v-model="item.lastAction" color="green" disabled label=""></v-switch>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon class="mr-2" color="blue" v-bind="attrs" v-on="on" @click="editItem(item)">
              mdi-pencil
            </v-icon>
          </template>
          <span>Bearbeiten</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="green" dark v-bind="attrs" v-on="on" @click="showSupplierStockTemplates(item)">
              mdi-file-delimited
            </v-icon>
          </template>
          <span>Zuordnung</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="black" dark v-bind="attrs" v-on="on" @click="showSupplierStockTemplatesFilter(item)">
              mdi-filter
            </v-icon>
          </template>
          <span>Filter</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="orange" dark v-bind="attrs" v-on="on" @click="showSupplierStockTemplatesShippings(item)">
              mdi-truck-delivery
            </v-icon>
          </template>
          <span>Frachten</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="black" dark v-bind="attrs" v-on="on" @click="showSupplierStockTemplatesMinQuantity(item)">
              mdi-quality-medium
            </v-icon>
          </template>
          <span>Mindestmengen</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="red" dark v-bind="attrs" v-on="on" @click="showSupplierStockTemplatesPriceMarkDowns(item)">
              mdi-cash
            </v-icon>
          </template>
          <span>Preisabschläge</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="green" dark v-bind="attrs" v-on="on" @click="showSupplierStockTemplatesPriceMarkUps(item)">
              mdi-cash
            </v-icon>
          </template>
          <span>Preisaufschäge</span>
        </v-tooltip>
        <v-tooltip bottom v-if="editdata.isMainSupplier > 0">
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="blue" dark v-bind="attrs" v-on="on" @click="showSupplierStockTemplatesSumUp(item)">
              mdi-package-variant-closed-plus
            </v-icon>
          </template>
          <span>Bestandssummierungen</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" @click="deleteItem(item)" color="red">
              mdi-delete
            </v-icon>
          </template>
          <span>Löschen</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <template>
      <v-row>
        <v-col cols="3">
          <v-btn elevation="2" rounded small v-on:click="back">Zurück</v-btn>
        </v-col>
      </v-row>
    </template>
  </v-app>
</template>
<script>
  export default {
    props: {
        value: Array,
        editdata: null,
        url: {
            type: String,
            default: '/admin/supplierstocktemplate'
        },
        callback: {
                type: Function,
                default: () => ({})
            },
            catch: {
                type: Function,
                default: () => ({})
            },
            finally: {
                type: Function,
                default: () => ({})
            },
    },

    data: () => ({
      supplierstocktemplates: [],
      messagedialog: false,
      dialog: false,
      dialogDelete: false,
      rules: {
          name: [val => (val || '').length > 0 || 'Erforderlich'],
          number: [val => val > 0 || `Erforderlich`],
          percent: [val => val >= 0 && !isNaN(parseFloat(val)) || `Es muss eine Zahl eingegeben werden!`],
        },
      headers: [
        {
          text: 'Id',
          align: 'start',
          sortable: true,
          value: 'id',
        },
        { text: 'Bestandsvorlage', value: 'templateName' },
        { text: 'Lieferantennummer', value: 'supplierNumber' },
        { text: 'Artikelanzahl-Abweichung (%)', value: 'rowCountDeviationPercent' },
        { text: 'Letzte importierte Artikelanzahl', value: 'lastImportedRowCount' },
        
        { text: 'Import-Zeitüberschreitung (Minuten)', value: 'import_timeout' },
        { text: 'Importiert am', value: 'imported_at' },
        { text: 'Beschreibung', value: 'description' },
        { text: 'Status', value: 'lastAction' },
        { text: 'Aktionen', value: 'actions', sortable:false },
      ],
      success:false,
      suppliers: [],
      editedItem: {
        id: 0,
        supplier_id : 0,
        templateName: null,
        supplierNumber: null,
        rowCountDeviationPercent: 0,
        lastImportedRowCount: 0,
        import_timeout: 0,
        description: null,
        lastAction: 1,
      },
      defaultItem: {
        id: 0,
        supplier_id: 0,
        templateName: null,
        supplierNumber: null,
        rowCountDeviationPercent: 0,
        lastImportedRowCount: 0,
        import_timeout: 0,
        description: null,
        lastAction: 1,
      },
      // suppliertypeoptions:[ { value: 0, text: 'Großhändler' },{ value: 1, text: 'Hersteller' },{ value: 2, text: 'Platform' }],
    }),

    computed: {
      formTitle () {
        return this.editedItem.id === 0 ? 'Lieferantenvorlage hinzufügen' : 'Lieferantenvorlage bearbeiten'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    created () {
      this.editedItem.supplier_id = this.editdata.id;
      this.defaultItem.supplier_id = this.editdata.id;
      this.getSupplierStocktTemplates();
    },

    methods: {
      back: function(){
            window.location = location.protocol + "//" + location.hostname + "/admin/suppliers/";
      },
      async getSupplierStocktTemplates() {
          axios.get('/admin/supplier/'+this.editdata.id+'/getsupplierstocktemplates')
              .then(function (response) { 
                  this.supplierstocktemplates = response.data;
              }.bind(this));
      },
      getDateTime(datetime)
      {
        var d = new Date(datetime);
        return d.toLocaleString("de-DE");
      },
      showSupplierStockTemplates(item){
        let supplierstocktemplates_url = '/admin/supplierstocktemplate/'+item.id+'/supplierstocktemplatemappings'; 
        if(this.editdata.supplierType == 2)
        {
          supplierstocktemplates_url = '/admin/supplierstocktemplate/'+item.id+'/supplierstocktemplateplatformmappings'; 
        }
        window.location = location.protocol + "//" + location.hostname + supplierstocktemplates_url;
      },

      showSupplierStockTemplatesFilter(item){
        window.location = location.protocol + "//" + location.hostname + '/admin/supplierstocktemplatefilter/'+item.id;
      },
      showSupplierStockTemplatesShippings(item){
        window.location = location.protocol + "//" + location.hostname + '/admin/supplierstocktemplateshippings/'+item.id;
      },
      showSupplierStockTemplatesMinQuantity(item){
        window.location = location.protocol + "//" + location.hostname + '/admin/supplierstockmindestmenge/'+item.id;
      },
      showSupplierStockTemplatesPriceMarkDowns(item) {
        window.location = location.protocol + "//" + location.hostname + '/admin/supplierstockpricemarkdown/'+item.id;
      },
      showSupplierStockTemplatesPriceMarkUps(item) {
        window.location = location.protocol + "//" + location.hostname + '/admin/supplierstockpricemarkup/'+item.id;
      },
      showSupplierStockTemplatesSumUp(item) {
        window.location = location.protocol + "//" + location.hostname + '/admin/supplierstockssumup/'+item.id;
      },
      editItem (item) {
        this.editedItem = item;
        this.dialog = true
      },

      deleteItem (item) {
        this.editedItem = item;
        this.dialog = true
      },

      deleteItemConfirm () {
        axios.delete(this.url+'/'+this.editedItem.id)
          .then(response => {
          this.showMsgBoxOne(response, true);
          }).catch(response => {
              _this.catch(response);
          }).finally(response => {
              _this.finally(response);
          });
        this.closeDelete();
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = this.defaultItem; 
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = this.defaultItem;
        })
      },

      save () {
        const _this = this;
        if(this.editedItem.id > 0){
            axios.put(this.url+'/'+this.editedItem.id,
                this.editedItem,
            ).then(response => {
               _this.callback(response);
                this.showMsgBoxOne(response, true);
            }).catch(response => {
                _this.catch(response);
            }).finally(response => {
                _this.finally(response);
            });
        }else{
            axios.post(this.url,
                this.editedItem,
            ).then(response => {
                _this.callback(response);
                this.showMsgBoxOne(response, true);
            }).catch(response => {
                _this.catch(response);
            }).finally(response => {
                _this.finally(response);
            });
        }

        // Hide the modal manually
        this.$nextTick(() => {
           // this.resetModal();
            this.$bvModal.hide('modal-prevent-closing')
        })
        this.close()
      },

      showMsgBoxOne(callback, change) {
        this.$bvModal.msgBoxOk(callback.data)
        .then(value => {
            if(change)
            {
              this.getSupplierStocktTemplates();
            }
        })
        .catch(err => {
            // An error occurred
        })
      },
    },
  }
</script>