<template>
    <v-app>
        <v-data-table :headers="headers" :items="supplierstockshippingcountries" :footer-props="{
            showFirstLastPage: true,
            showCurrentPage: true,
            itemsPerPageOptions: [50, 100, 150, -1],
            itemsPerPageAllText: 'Alle',
            itemsPerPageText: 'Datensätze pro Seite',
            pageText: '{0}-{1} von {2}'
        }">
            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title>Frachten der Lieferantenbestandsvorlage {{ editdata.templateName }}
                    </v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>

                    <!-- <div class="d-flex w-100"> -->
                    <!-- <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" dense outlined
                            single-line hide-details></v-text-field> -->
                    <v-btn color="success" class="mx-2" fab dark small @click="addNew">
                        <v-icon dark>mdi-plus</v-icon>
                    </v-btn>
                    <!-- </div> -->

                    <v-spacer></v-spacer>
                    <v-dialog v-model="dialog" max-width="500px">
                        <!-- <template v-slot:activator="{ on, attrs }">
                            <v-btn color="success" class="mx-2" fab dark small v-bind="attrs" v-on="on"
                                @click="addNew">>
                                <v-icon dark>
                                    mdi-plus
                                </v-icon>
                            </v-btn>
                        </template> -->
                        <v-card>
                            <v-app-bar dark color="success">
                                <v-card-title>
                                    <span class="text-h5">{{ formTitle }}</span>
                                </v-card-title>
                            </v-app-bar>
                            <v-card-text>
                                <v-container>
                                    <v-row>
                                        <v-col cols="6">
                                            <v-text-field v-model="editdata.templateName" label="Lieferantenvorlage*"
                                                disabled></v-text-field>
                                        </v-col>
                                        <v-col cols="6">
                                            <v-text-field v-model="editdata.supplierNumber" label="Lieferantennummer*"
                                                disabled></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="6">
                                            <v-autocomplete v-model="editedItem.code" :items="countryoptions"
                                                item-value="code" item-text="country" label="Land*" required>
                                            </v-autocomplete>
                                        </v-col>
                                        <v-col cols="6">
                                            <v-text-field v-model="editedItem.parsel" label="Anzahl*" required>
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="6">
                                            <v-text-field v-model="editedItem.price" type="number" min="0" value="0"
                                                step="0.01" label="Preis*" @input="formatNumber" required>
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="6">
                                            <v-text-field v-model="editedItem.oneunitprice" type="number" min="0"
                                                value="0" step="0.01" label="Einzelpreis*" @input="formatNumber"
                                                required>
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="6">
                                            <v-text-field v-model="editedItem.warengruppevon" type="number" min="1"
                                                value="0" step="1" label="Warengruppe-Von*" required>
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="6">
                                            <v-text-field v-model="editedItem.warengruppebis" type="number" min="1"
                                                value="0" step="1" label="Warengruppe-Bis*" required>
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="6">
                                            <v-text-field v-model="editedItem.zollgrossevon" type="number" min="1"
                                                value="0" step="1" label="Zollgr. von">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="6">
                                            <v-text-field v-model="editedItem.zollgrossebis" type="number" min="1"
                                                value="0" step="1" label="Zollgr. bis">
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="6">
                                            <v-text-field v-model="editedItem.gewicht" type="number" min="0" value="0"
                                                step="0.01" label="Gewicht">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="6">
                                            <v-text-field v-model="editedItem.uebergewicht" label="Übergewicht">
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="6">
                                            <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40"
                                                transition="scale-transition" offset-y max-width="290px"
                                                min-width="290px">
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field v-model="computedStartDateFormatted"
                                                        label="Startdatum" persistent-hint prepend-icon="event" readonly
                                                        v-on="on">
                                                    </v-text-field>
                                                </template>
                                                <v-date-picker v-model="editedItem.startdate" no-title
                                                    @input="menu = false" @change="dateChanged">
                                                    <v-btn text @click="onClearSurchargeStartDate"
                                                        v-if="editedItem.startdate">
                                                        Löschen
                                                    </v-btn>
                                                </v-date-picker>
                                            </v-menu>
                                        </v-col>
                                        <v-col cols="6">
                                            <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40"
                                                transition="scale-transition" offset-y max-width="290px"
                                                min-width="290px">
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field v-model="computedStopDateFormatted" label="Enddatum"
                                                        persistent-hint prepend-icon="event" readonly
                                                        v-on="on"></v-text-field>
                                                </template>
                                                <v-date-picker v-model="editedItem.stopdate" no-title
                                                    @input="menu2 = false" @change="dateChanged">
                                                    <v-btn text @click="onClearStopDate" v-if="editedItem.stopdate">
                                                        Löschen
                                                    </v-btn>
                                                </v-date-picker>
                                            </v-menu>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-text-field label="Beschreibung" v-model="editedItem.description"
                                                hide-details="auto">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-switch v-model="editedItem.LastAction"
                                                :label="`Fracht ${editedItem.LastAction > 0 ? 'aktiviert' : 'deaktiviert'}`"
                                                color="green"></v-switch>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="error" text @click="close">
                                    Abbrechen
                                </v-btn>
                                <v-btn color="primary" @click="save">
                                    Speichern
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <v-dialog v-model="dialogDelete" max-width="500px">
                        <v-card>
                            <v-card-title class="text-h5">Sind Sie sicher, dass Sie diese Fracht löschen
                                möchten?</v-card-title>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="closeDelete">Abbrechen</v-btn>
                                <v-btn color="blue darken-1" text @click="deleteItemConfirm">Ja</v-btn>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <v-dialog v-model="dialogWarning" max-width="600px">
                        <v-card>
                            <v-card-title class="text-h5 warning">Das Startdatum darf nicht größer als Enddatum
                                sein!</v-card-title>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="closeDialogWarning">OK</v-btn>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-toolbar>
            </template>
            <template v-slot:item.country="{ item }">
                <v-autocomplete v-model="editedItem.code" :items="countryoptions" item-value="code" item-text="country"
                    label="Land*" required dense single-line v-if="item.id === editedItem.id">
                </v-autocomplete>
                <span v-else>{{ item.country }}</span>
            </template>
            <template v-slot:item.parsel="{ item }">
                <v-text-field v-model="editedItem.parsel" dense single-line :autofocus="true" type="number" min="0"
                    value="0" step="1" v-if="item.id === editedItem.id"></v-text-field>
                <span v-else>{{ item.parsel }}</span>
            </template>
            <template v-slot:item.price="{ item }">
                <v-text-field v-model="editedItem.price" type="number" min="0" value="0" step="0.01"
                    @input="formatNumber" dense single-line required v-if="item.id === editedItem.id">
                </v-text-field>
                <span v-else>{{ item.price }}</span>
            </template>
            <template v-slot:item.oneunitprice="{ item }">
                <v-text-field v-model="editedItem.oneunitprice" type="number" min="0" value="0" step="0.01"
                    @input="formatNumber" required dense single-line v-if="item.id === editedItem.id">
                </v-text-field>
                <span v-else>{{ item.oneunitprice }}</span>
            </template>
            <template v-slot:item.warengruppevon="{ item }">
                <v-text-field v-model="editedItem.warengruppevon" type="number" min="1" value="0" step="1" required
                    dense single-line v-if="item.id === editedItem.id">
                </v-text-field>
                <span v-else>{{ item.warengruppevon }}</span>
            </template>
            <template v-slot:item.warengruppebis="{ item }">
                <v-text-field v-model="editedItem.warengruppebis" type="number" min="1" value="0" step="1" required
                    dense single-line v-if="item.id === editedItem.id">
                </v-text-field>
                <span v-else>{{ item.warengruppebis }}</span>
            </template>
            <template v-slot:item.zollgrossevon="{ item }">
                <v-text-field v-model="editedItem.zollgrossevon" type="number" min="1" value="0" step="1" dense
                    single-line v-if="item.id === editedItem.id">
                </v-text-field>
                <span v-else>{{ item.zollgrossevon }}</span>
            </template>
            <template v-slot:item.zollgrossebis="{ item }">
                <v-text-field v-model="editedItem.zollgrossebis" type="number" min="1" value="0" step="1" dense
                    single-line required v-if="item.id === editedItem.id">
                </v-text-field>
                <span v-else>{{ item.zollgrossebis }}</span>
            </template>
            <template v-slot:item.gewicht="{ item }">
                <v-text-field v-model="editedItem.gewicht" type="number" min="0" value="0" step="0.01" dense single-line
                    v-if="item.id === editedItem.id"></v-text-field>
                <span v-else>{{ item.gewicht }}</span>
            </template>
            <template v-slot:item.uebergewicht="{ item }">
                <v-text-field v-model="editedItem.uebergewicht" dense single-line v-if="item.id === editedItem.id">
                </v-text-field>
                <span v-else>{{ item.uebergewicht }}</span>
            </template>
            <template v-slot:item.startdate="{ item }">
                <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                    offset-y max-width="290px" min-width="290px" v-if="item.id === editedItem.id">
                    <template v-slot:activator="{ on }">
                        <v-text-field v-model="computedStartDateFormatted" label="Startdatum" persistent-hint
                            prepend-icon="event" readonly v-on="on" dense single-line>
                        </v-text-field>
                    </template>
                    <v-date-picker v-model="editedItem.startdate" no-title @input="menu = false" @change="dateChanged">
                        <v-btn text @click="onClearSurchargeStartDate" v-if="editedItem.startdate">
                            Löschen
                        </v-btn>
                    </v-date-picker>
                </v-menu>
                <span v-else>{{ item.display_startdate }}</span>
            </template>
            <template v-slot:item.stopdate="{ item }">
                <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                    offset-y max-width="290px" min-width="290px" v-if="item.id === editedItem.id">
                    <template v-slot:activator="{ on }">
                        <v-text-field v-model="computedStopDateFormatted" label="Enddatum" persistent-hint
                            prepend-icon="event" readonly v-on="on" dense single-line></v-text-field>
                    </template>
                    <v-date-picker v-model="editedItem.stopdate" no-title @input="menu2 = false" @change="dateChanged">
                        <v-btn text @click="onClearStopDate" v-if="editedItem.stopdate">
                            Löschen
                        </v-btn>
                    </v-date-picker>
                </v-menu>
                <span v-else>{{ item.display_stopdate }}</span>
            </template>
            <template v-slot:item.description="{ item }">
                <v-text-field v-model="editedItem.description" dense single-line v-if="item.id === editedItem.id">
                </v-text-field>
                <span v-else>{{ item.description }}</span>
            </template>
            <template v-slot:item.LastAction="{ item }">
                <v-switch v-model="editedItem.LastAction" color="green" dense single-line
                    v-if="item.id === editedItem.id"></v-switch>
                <v-switch v-model="item.LastAction" color="green" readonly v-else></v-switch>
            </template>
            <template v-slot:item.actions="{ item }">
                <div v-if="item.id === editedItem.id">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon color="red" class="mr-3" v-bind="attrs" v-on="on" @click="close">
                                mdi-window-close
                            </v-icon>
                        </template>
                        <span>Abbrechen</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon color="green" v-bind="attrs" v-on="on" @click="save">
                                mdi-content-save
                            </v-icon>
                        </template>
                        <span>Speichern</span>
                    </v-tooltip>
                </div>
                <div v-else>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon class="mr-2" color="blue" v-bind="attrs" v-on="on" @click="editItem(item)">
                                mdi-pencil
                            </v-icon>
                        </template>
                        <span>Bearbeiten</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on" @click="deleteItem(item)" color="red">
                                mdi-delete
                            </v-icon>
                        </template>
                        <span>Löschen</span>
                    </v-tooltip>
                </div>
            </template>
        </v-data-table>
        <template>
            <v-row>
                <v-col cols="3">
                    <v-btn elevation="2" rounded small v-on:click="back">Zurück</v-btn>
                </v-col>
            </v-row>
        </template>
    </v-app>
</template>
<script>
export default {
    props: {
        value: Array,
        editdata: null,
        url: {
            type: String,
            default: '/admin/supplierstockshippingcountries'
        },
        callback: {
            type: Function,
            default: () => ({})
        },
        catch: {
            type: Function,
            default: () => ({})
        },
        finally: {
            type: Function,
            default: () => ({})
        },
    },

    data: vm => ({
        menu: false,
        menu2: false,
        countryoptions: [],
        supplierstockshippingcountries: [],
        messagedialog: false,
        dialog: false,
        dialogDelete: false,
        dialogWarning: false,
        rules: {
            name: [val => (val || '').length > 0 || 'Erforderlich'],
            number: [val => val > 0 || `Erforderlich`],
        },
        headers: [
            { text: 'Id', align: 'start', sortable: true, value: 'id', },
            { text: 'Land', value: 'country' },
            { text: 'Anzahl', value: 'parsel' },
            { text: 'Preis', value: 'price' },
            { text: 'Einzelpreis', value: 'oneunitprice' },
            { text: 'WG-von', value: 'warengruppevon' },
            { text: 'WG-bis', value: 'warengruppebis' },
            { text: 'Zollgr. von', value: 'zollgrossevon' },
            { text: 'Zollgr. bis', value: 'zollgrossebis' },
            { text: 'Gewicht', value: 'gewicht' },
            { text: 'Übergewicht', value: 'uebergewicht' },
            { text: 'Startdatum', value: 'startdate' },
            { text: 'Enddatum', value: 'stopdate' },
            // { text: 'Startdatum', value: 'display_surchargestartdate' },
            // { text: 'Enddatum', value: 'display_surchargestopdate' },
            { text: 'Beschreibung', value: 'description' },
            { text: 'Status', value: 'LastAction' },
            { text: 'Aktionen', value: 'actions', sortable: false },

        ],
        success: false,
        editedIndex: -1,
        editedItem: {
            id: 0,
            supplierstocktemplate_id: vm.editdata.id,
            supplierName: vm.editdata.supplier.supplierName,
            supplierNumber: vm.editdata.supplierNumber,
            code: '',
            country: '',
            price: 0,
            oneunitprice: 0,
            parsel: 1,
            warengruppevon: 0,
            warengruppebis: 0,
            zollgrossevon: '',
            zollgrossebis: '',
            gewicht: '',
            uebergewicht: '',
            startdate: null,
            stopdate: null,
            display_startdate: '',
            startdatemenu: false,
            display_stopdate: '',
            stopdatemenu: false,
            description: '',
            LastAction: 1,
        },
        defaultItem: {
            id: 0,
            supplierstocktemplate_id: vm.editdata.id,
            supplierName: vm.editdata.supplier.supplierName,
            supplierNumber: vm.editdata.supplierNumber,
            code: '',
            country: '',
            price: 0,
            oneunitprice: 0,
            parsel: 1,
            warengruppevon: 0,
            warengruppebis: 0,
            zollgrossevon: '',
            zollgrossebis: '',
            gewicht: '',
            uebergewicht: '',
            startdate: null,
            stopdate: null,
            display_startdate: '',
            startdatemenu: false,
            display_stopdate: '',
            stopdatemenu: false,
            description: '',
            LastAction: 1,
        },
    }),
    created() {
        this.getCountries();
        this.getSupplierShippingsCountries();
    },
    computed: {
        computedStartDateFormatted() {
            return this.formatDate(this.editedItem.startdate)
        },
        computedStopDateFormatted() {
            return this.formatDate(this.editedItem.stopdate)
        },
        formTitle() {
            return this.editedItem.id === 0 ? 'Neue Fracht hinzufügen' : 'Fracht bearbeiten'
        },
    },

    watch: {
        dialog(val) {
            val || this.close()
        },
        dialogDelete(val) {
            val || this.closeDelete()
        },
        dialogWarning(val) {
            val || this.closeDialogWarning()
        },

        menu(val) {

        }
    },

    methods: {

        back: function () {
            window.location = location.protocol + "//" + location.hostname + "/admin/supplier/" + this.editdata.supplier_id +"/supplierstocktemplates";
        },

        close() {
            setTimeout(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            }, 300)
        },
        save() {
            if (this.editedIndex > -1) {
                Object.assign(this.supplierstockshippingcountries[this.editedIndex], this.editedItem)
            }
            this.close()
        },
        addNew() {
            const addObj = Object.assign({}, this.defaultItem);
            // addObj.id = this.supplierstockshippingcountries.length + 1;
            this.supplierstockshippingcountries.unshift(addObj);
            this.editItem(addObj);
        },
        getCountries: function () {
            axios.get('/admin/getshippingcountries')
                .then(function (response) {
                    this.countryoptions = response.data;
                }.bind(this));
        },
        dateChanged: function () {
            if (this.editedItem.stopdate !== null && (this.editedItem.startdate >= this.editedItem.stopdate)) {
                this.dialogWarning = true
            }
        },
        formatNumber() {
            // this.editedItem.surcharge = new Intl.NumberFormat('de-DE', { style: 'decimal' }).format(this.editedItem.surcharge);
        },
        onClearSurchargeStartDate: function () {
            this.editedItem.startdate = null;
            this.menu = false;
        },
        onClearStopDate: function () {
            this.editedItem.stopdate = null;
            this.menu2 = false;
        },
        getSupplierShippingsCountries: function () {
            axios.get('/admin/suppliershippingcountries/getshippingscountries/' + this.editdata.id)
                .then(function (response) {
                    this.supplierstockshippingcountries = response.data;
                }.bind(this));
        },

        formatDate(date) {
            if (!date) return null
            const [year, month, day] = date.split('-')
            return `${day}.${month}.${year}`
        },

        editItem(item) {
            const _this = this;
            this.editedIndex = this.supplierstockshippingcountries.indexOf(item);
            this.editedItem = Object.assign({}, item);
            // _this.editedItem = item;
            // _this.dialog = true
        },

        deleteItem(item) {
            this.editedItem = item;
            this.dialogDelete = true
        },

        deleteItemConfirm() {
            const _this = this;
            axios.delete(this.url + '/' + _this.editedItem.id)
                .then(response => {
                    _this.showMsgBoxOne(response, true);
                }).catch(response => {
                    _this.catch(response);
                }).finally(response => {
                    _this.finally(response);
                });
            _this.closeDelete();
        },

        // close() {
        //     this.dialog = false
        //     this.$nextTick(() => {
        //         this.editedItem = this.defaultItem;
        //     })
        // },

        closeDelete() {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = this.defaultItem;
            })
        },

        closeDialogWarning() {
            this.dialogWarning = false
        },
        save() {
            const _this = this;
            // _this.editedItem.name = _this.getWholesalerName();
            if (this.editedItem.id > 0) {
                axios.put(this.url + '/' + this.editedItem.id,
                    this.editedItem,
                ).then(response => {
                    _this.callback(response);
                    this.showMsgBoxOne(response, true);
                }).catch(response => {
                    _this.catch(response);
                }).finally(response => {
                    _this.finally(response);
                });
            } else {
                axios.post(this.url,
                    this.editedItem,
                ).then(response => {
                    _this.callback(response);
                    this.showMsgBoxOne(response, true);
                }).catch(response => {
                    _this.catch(response);
                }).finally(response => {
                    _this.finally(response);
                });
            }

            // Hide the modal manually
            this.$nextTick(() => {
                // this.resetModal();
                this.$bvModal.hide('modal-prevent-closing')
            })
            this.close()
        },

        showMsgBoxOne(callback, change) {
            this.$bvModal.msgBoxOk(callback.data)
                .then(value => {
                    if (change) {
                        this.getSupplierShippingsCountries();
                    }
                })
                .catch(err => {
                    // An error occurred
                })
        },
    },
}
</script>