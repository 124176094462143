<template>
    <div class="vue-csv-uploader">
        <v-app>
            <v-form ref="form">
                <v-row>
                    <v-col cols="12" sm="6" md="3">
                        <v-text-field v-model="platformName" label="Platform" readonly></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                        <v-text-field v-model="platformtemplateName" label="Vorlagename" readonly></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="4">
                        <v-text-field v-model="ownoffertask.previousofferstate_display" label="Vorheriger Status"
                            readonly></v-text-field>
                    </v-col>
                    <v-col cols="4">
                        <v-text-field v-model="ownoffertask.lastofferstate_display" label="Letzter Status"
                            readonly></v-text-field>
                    </v-col>
                    <v-col cols="4">
                        <v-text-field v-model="ownoffertask.lastcalculatedoffer_at"
                            label="Letzte Berechnung am" readonly></v-text-field>
                    </v-col>
                </v-row>
                <!-- <v-row>
                <v-col
                cols="12"
                sm="6"
                md="3"
                >
                    <v-text-field
                    v-model="ownoffertask.markupstarttime"
                    label="Startzeit der Preisaufschläge"
                    type="time"
                    append-icon="mdi-close"
                    ></v-text-field>
                </v-col> 
                <v-col
                cols="12"
                sm="6"
                md="3"
                >
                    <v-text-field
                    v-model="ownoffertask.markupendtime"
                    label="Endzeit der Preisaufschläge"
                    type="time"
                    ></v-text-field>
                </v-col> 
            </v-row> -->
                <v-row>
                    <v-col cols="4">
                        <v-select v-model="ownoffertask.taskinterval" :items="intervals"
                            label="Berechnungsintervall (in Minuten)"></v-select>
                    </v-col>
                    <v-col cols="4">
                        <v-text-field v-model="ownoffertask.markdowncount"
                            label="Anzahl der letzten durchgeführten Preisabschläge" readonly></v-text-field>
                    </v-col>
                    <v-col cols="4">
                        <v-text-field v-model="ownoffertask.markdowncountlimit" label="Anzahl der Preisabschläge"
                            hint="Anzahl der Preisabschläge nach den ersten Angeboten" type="number"
                            min="0"></v-text-field>
                    </v-col>
                </v-row>
                <v-spacer></v-spacer>
                <v-row>
                    <v-btn-toggle borderless>
                        <v-btn value="left" @click="back">
                            Zurück
                        </v-btn>
                        <slot name="submit" :submit="submit">
                            <v-btn color="success" @click="submit">
                                Speichern
                            </v-btn>
                        </slot>
                    </v-btn-toggle>
                </v-row>
            </v-form>
        </v-app>
    </div>
</template>

<script>
    import axios from 'axios';
    export default {
        props: {
            value: Array,
            editdata: null,
            url: {
                type: String,
                default: '/admin/ownoffertasks/'
            },

            callback: {
                type: Function,
                default: () => ({})
            },
            catch: {
                type: Function,
                default: () => ({})
            },
            finally: {
                type: Function,
                default: () => ({})
            },
            headers: {
                default: null
            },
            submitBtnText: {
                type: String,
                default: "Speichern"
            },
            buttonClass: {
                type: String,
                default: "btn btn-primary"
            },
            inputClass: {
                type: String,
                default: "form-control-file"
            },
            validation: {
                type: Boolean,
                default: true,
            },
            tableSelectClass: {
                type: String,
                default: 'form-control'
            },
        },

        data: () => ({
           
            rules: {
                required: value => !!value || 'Erforderlich',
                counter: value => value.length <= 20 || 'Max 20 characters',
                price: value => {
                    return /^\d+(\.\d+)*$/.test(value) || 'Ungültig'
                },
            },
            enableSubmit: false,
            platformtemplate_id:0,
            platformName: '',
            platformtemplateName: '',
            intervals: [30, 60, 90, 120],
            ownoffertask: {
                id: 0,
                platformtemplate_id:'',
                markdowncountlimit: 3,
                markupstarttime: null,
                markupendtime: null,
                taskinterval: 30
            },
        }),

        created() {
            this.ownoffertask.id = this.editdata.id;
            this.ownoffertask.platformtemplate_id = this.editdata.platformtemplate_id;
            this.ownoffertask.markdowncountlimit = this.editdata.markdowncountlimit;
            this.ownoffertask.markupstarttime = this.editdata.markupstarttime;
            this.ownoffertask.markupendtime = this.editdata.markupendtime;
            this.ownoffertask.taskinterval = this.editdata.taskinterval;
            this.ownoffertask.previousofferstate_display = this.getStateDisplay(this.editdata.previousofferstate);
            this.ownoffertask.lastofferstate_display = this.getStateDisplay(this.editdata.lastofferstate);
            this.ownoffertask.markdowncount = this.editdata.markdowncount;
            this.ownoffertask.lastcalculatedoffer_at = this.editdata.lastcalculatedoffer_at;
            this.platformName = this.editdata.platformtemplate.platformname;
            this.platformtemplate_id = this.editdata.platformtemplate_id;
            this.platformtemplateName = this.editdata.platformtemplate.name;
          
        },

        methods: {
            back: function(){
                window.location = location.protocol + "//" + location.hostname + "/admin/platforms/"+this.editdata.platformtemplate.platformid+"/platformtemplates";
            },
            getStateDisplay(state)
            {
                if (state === 'CREATEOFFER')
                {
                    return 'NEUE BERECHNUNG';
                } else if (state === 'MARKDOWNOFFER')
                {
                    return 'PREISABSCHLAG';
                }
                else if (state === 'MARKUPOFFER') {
                    return 'PREISAUFSCHLAG';
                }
                return 'ANFANGSZUSTAND';
            },
            submit() {
                const _this = this;
                axios.put(this.url + this.ownoffertask.id,
                    this.ownoffertask,
                ).then(response => {
                   this.showMsgBoxOne(response, true);
                }).catch(response => {
                    _this.catch(response);
                    console.log(response);
                }).finally(response => {
                    _this.finally(response);
                });
            },
            showMsgBoxOne(callback, change) {
                if(callback.data.data.code > 200){
                        this.$bvModal.msgBoxOk(callback.data.data.message, {
                        title: 'FEHLER',
                        headerBgVariant: 'danger',
                        headerTextVariant: 'light',
                        titleVariant: 'danger',
                        //bodyBgVariant: 'warning',
                        bodyTextVariant:'danger',
                        size:'lg',
                        okVariant: 'warning'
                         }).then(value => {
                    if (change) {
                        window.location = location.protocol + "//" + location.hostname + "/admin/platformtemplate/" + this.platformtemplate_id + "/ownoffertask";
                    }
                })
                    return;
                }
                this.$bvModal.msgBoxOk(callback.data.data.message, {
                    title: 'MELDUNG',
                    headerBgVariant: 'success',
                    headerTextVariant: 'light',
                    titleVariant: 'success',
                    //bodyBgVariant: 'warning',
                    bodyTextVariant:'success',
                    size:'lg',
                    okVariant: 'success'
                        });
            },

            checkDate(date){
                return /^\d+(\.\d\d)*$/.test(date);
            },
            makeErrorToast() {
                this.$bvToast.toast('Test', {
                title: 'Fehler'
                })
            },
            showError(mp) {
                if(mp.prozent == null)
                 {
                     this.$bvModal.msgBoxOk('Prozent darf nicht leer sein!'+ mp, {
                        title: 'FEHLER',
                        headerBgVariant: 'danger',
                        headerTextVariant: 'light',
                        titleVariant: 'danger',
                        //bodyBgVariant: 'warning',
                        bodyTextVariant:'danger',
                        size:'lg',
                        okVariant: 'warning'
                         });
                 }
            }
        },
        watch: {
        },
        computed: {

        },
    };
</script>
