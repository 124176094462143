/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue').default;
import VueAxios from 'vue-axios';
import axios from 'axios';
//import vSelect from 'vue-select';
import { VueCsvImport } from 'vue-csv-import';
import 'vuetify/dist/vuetify.min.css';
import '@mdi/font/css/materialdesignicons.css';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import vuetify from './plugins/vuetify';
import ConvertToCurrencyComponent from './components/ConvertToCurrencyComponent.vue'
Vue.use(ConvertToCurrencyComponent);

import CheckShippingCost from './components/admin/checkshippingcost/CheckShippingCostComponent.vue';
Vue.use(CheckShippingCost);

import FtpConnectionTester from './components/FtpConnectionTester.vue';
Vue.component('ftp-tester', FtpConnectionTester);
import { func } from './plugins/functions';

Vue.use(VueAxios, axios);
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import Vue from 'vue';
Vue.prototype.$func = func;
// Install BootstrapVue
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
import "bootstrap-vue/dist/bootstrap-vue.css";
Vue.component('vue-csv-import', VueCsvImport);


/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

Vue.component('MessageComponent', require('./components/MessageComponent.vue').default);

Vue.component('example-component', require('./components/ExampleComponent.vue').default);

//Cartomak Config
Vue.component('edit-cartomakstocksupplier-component', require('./components/admin/cartomakstocksupplierconfig/EditCartomakStockSupplierConfigComponent.vue').default);

//Pricescanner Supplier Stock
Vue.component('add-supplier-stock-template-component', require('./components/admin/supplierstocktemplate/AddSupplierStockTemplateComponent.vue').default);
Vue.component('edit-supplier-stock-template-component', require('./components/admin/supplierstocktemplate/EditSupplierStockTemplateComponent.vue').default);
//Pricescanner SupplierStockShippingCountries

Vue.component('view-countries-component', require('./components/admin/supplierstocktemplatecountries/SupplierStockTemplateCountriesComponent.vue').default);
Vue.component('add-supplierstock-template-countries-component', require('./components/admin/supplierstocktemplatecountries/AddSupplierStockTemplateCountries.vue').default);

//Pricescanner ShippingCountries
Vue.component('add-shipping-countries-component', require('./components/admin/shippingcountries/AddShippingCountriesComponent.vue').default);
Vue.component('edit-shipping-countries-component', require('./components/admin/shippingcountries/EditShippingCountriesComponent.vue').default);

Vue.component('add-supplier-stock-template-filter-component', require('./components/admin/supplierstocktemplatefilter/AddSupplierStockTemplateFilter.vue').default);

/**
 * Platform
 */
Vue.component('add-platform-component', require('./components/admin/platforms/AddPlatformComponent.vue').default);
Vue.component('edit-platform-component', require('./components/admin/platforms/EditPlatformComponent.vue').default);

/**
 * Vorlage hinzufügen
 */
Vue.component('add-platform-template-component', require('./components/admin/platformtemplate/AddPlatformTemplateComponent.vue').default);
Vue.component('edit-platform-template-component', require('./components/admin/platformtemplate/EditPlatformtemplateComponent.vue').default);

//Mindestpreis der Vorlage
Vue.component('add_platformtemplate_mindestpreis-component', require('./components/admin/platformtemplatemindestpreis/AddPlatformtemplateMindestpreis.vue').default);

//Die Händler der Vorlage
Vue.component('add-platformtemplate-wholesaler-component', require('./components/admin/platformtemplatewholesaler/AddPlatformtemplateWholesaler.vue').default);

//Händler anlegen
Vue.component('add-wholesaler-component', require('./components/admin/wholesaler/AddWholesalerComponent.vue').default);
Vue.component('edit-wholesaler-component', require('./components/admin/wholesaler/EditWholesalerComponent.vue').default);

//MasterArtikel bearbeiten
Vue.component('edit-masterartikel-component', require('./components/admin/masterartikel/EditMasterArtikelComponent.vue').default);

//Mindestmenge des Lieferantes
Vue.component('add_supplierstock_mindestmenge-component', require('./components/admin/supplierstockmindestmenge/SupplierStockMindestmenge.vue').default);

//Mindestpreis-Konfiguration des Lieferantes
Vue.component('add_supplierstock_mindestpreisconf-component', require('./components/admin/supplierstockmindestpreisConf/AddSupplierStockMindestpreisConf.vue').default);

Vue.component('add_supplierstock_mindestpreise-component', require('./components/admin/supplierstockmindestpreis/SupplierStockMindestpreiseComponent.vue').default);

//Versanddienstleister
Vue.component('create-shipping-service-provider-component', require('./components/admin/shippingserviceprovider/CreateShippingServiceProviderComponent.vue').default);
Vue.component('edit-shipping-service-provider-component', require('./components/admin/shippingserviceprovider/EditShippingServiceProviderComponent.vue').default);

//Versandkosten der Versanddienstleister
Vue.component('shipping-service-charges-component', require('./components/admin/shippingservicecharges/ShippingServiceChargesComponent.vue').default);


//Versandkostenzuschäge eines Versanddienstleisters
Vue.component('shipping-service-surcharges-component', require('./components/admin/shippingservicesurcharges/ShippingServiceSurchargesComponent.vue').default);

//Warengruppen
Vue.component('warengruppen-component', require('./components/admin/warengruppen/WarengruppenComponent.vue').default);

//Saisonzuschläge der Großhändler
Vue.component('supplier-season-surcharge-component', require('./components/admin/supplierseasonsurcharge/SupplierSeasonSurchargeComponent.vue').default);

//Hersteller
Vue.component('hersteller-component', require('./components/admin/hersteller/HerstellerComponent.vue').default);

//Artikel
Vue.component('artikel-component', require('./components/admin/artikel/ArtikelComponent.vue').default);

//Dateisendug-Konfiguration einer Platformvorlage
Vue.component('create-platformexportfilesendconfig-component', require('./components/admin/platformexportfilesendconfig/CreatePlatformExportfileSendConfigComponent.vue').default);
Vue.component('edit-platformexportfilesendconfig-component', require('./components/admin/platformexportfilesendconfig/EditPlatformExportfileSendConfigComponent.vue').default);

//
Vue.component('platform-exportfile-config-component', require('./components/admin/platformexportfileconfig/PlatformExportfileConfig.vue').default);
//Preisabschläge der Händler einer Plattform
Vue.component('wholesaler-platform-pricemarkdown-component', require('./components/admin/wholesalerplatformpricemarkdown/WholesalerPlatformPricemarkdownComponent.vue').default);

//Standart Preisabschläge einer Plattform
Vue.component('wholesaler-default-pricemarkdown-component', require('./components/admin/wholesalerdefaultpricemarkdown/WholesalerDefaultPriceMarkdownComponent.vue').default);

//Preisabschläge eines Lagerbestandes
Vue.component('supplierstock-pricemarkdown-component', require('./components/admin/supplierstockpricemarkdown/SupplierStockPriceMarkdownComponent.vue').default);

//Hinzufügen eine Bestandsvorlage einer Plattformvorlage
Vue.component('add-platformstock-template-component', require('./components/admin/platformstocktemplate/AddPlatformStockTemplateComponent.vue').default);

//Bestandsvorlage einer Plattformvorlage bearbeiten.
Vue.component('edit-platformstock-template-component', require('./components/admin/platformstocktemplate/EditPlatformStockTemplateComponent.vue').default);

//Hinzufügen eine Bestandsvergleichvorlage einer Plattformvorlage
Vue.component('add-platformstockcompare-template-component', require('./components/admin/platformstockcomparetemplate/AddPlatformStockCompareTemplateComponent.vue').default);
Vue.component('edit-platformstockcompare-template-component', require('./components/admin/platformstockcomparetemplate/EditPlatformStockCompareTemplateComponent.vue').default);

//PlatformStocks
Vue.component('platformstocks-component', require('./components/admin/platformstock/PlatformStocksComponent.vue').default);

//Zuordnung der CSV-Plattformvorlagen
Vue.component('create-platformtemplatemapping-component', require('./components/admin/platformtemplatemapping/CreatePlatformtemplateMappingComponent.vue').default);
Vue.component('edit-platformtemplatemapping-component', require('./components/admin/platformtemplatemapping/EditPlatformtemplateMappingComponent.vue').default);

//ignorierte Lieferante einer Plattform
Vue.component('platform-default-ignoresupplierstock-component', require('./components/admin/platformignoresupplierstock/PlatformIgnoreSupplierStockComponent.vue').default);

//Angebote für Plattformen
Vue.component('platformownoffers-component', require('./components/admin/platformownoffer/PlatformOwnOfferComponent.vue').default);

Vue.component('ownofferexceededs-component', require('./components/admin/ownofferexceeded/OwnOfferExceededsComponent.vue').default);

//Preisaufschläge der Lieferantenbestände
Vue.component('supplierstock-pricemarkup-component', require('./components/admin/supplierstockpricemarkup/SupplierStockPriceMarkupComponent.vue').default);

//Bestandssummierung eines Lieferantenbestand
Vue.component('supplierstock-sumup-component', require('./components/admin/supplierstockssumup/SupplierStocksSumUpComponent.vue').default);

Vue.component('platformownoffer-markup-component', require('./components/admin/platformownoffermarkup/PlatformOwnOfferMarkUpComponent.vue').default);

//Konfiguration des Taskmanagers der Angebote pro Platformvorlage
Vue.component('ownoffertask-component', require('./components/admin/ownoffertasks/OwnOfferTaskComponent.vue').default);

Vue.component('platformtemplate-pricepercentage-component', require('./components/admin/platformtemplatepricepercentage/PlatformtemplatePricePercentage.vue').default);

//Rabatte der Lieferanten für die Plattformen
Vue.component('suppliestock_platform_discounts-component', require('./components/admin/supplierstockplatformdiscount/SupplierStockPlatformDiscountsComponent.vue').default);

Vue.component('ownoffersclientmodal-component', require('./components/admin/ownoffersclients/OwnOfferClientModalComponent.vue').default);

//Kunden der Bestandsdaten
Vue.component('ownoffersclients-component', require('./components/admin/ownoffersclients/OwnOffersClientsComponent.vue').default);

//Empfänger der Bestandsdateien der Plattformen
Vue.component('platformexportfilerecipients-component', require('./components/admin/platformexportfilerecipients/PlatformExportFileRecipientsComponent.vue').default);
//Lieferanten der Bestände
Vue.component('suppliers-component', require('./components/admin/supplier/SuppliersComponent.vue').default);

Vue.component('supplier-stock-templates-component', require('./components/admin/supplierstocktemplate/SupplierStockTemplatesComponent.vue').default);

//Zuordnung der Lieferantenvorlagen der Lieferantentypen Hersteller, Lieferant
Vue.component('supplier-stock-template-mappings-component', require('./components/admin/supplierstocktemplatemappings/SupplierStockTemplateMappingsComponent.vue').default);

//Zuordnung der Lieferantenvorlagen des Lieferantentypes Platform
Vue.component('supplier-stock-template-platform-mappings-component', require('./components/admin/supplierstocktemplateplatformmappings/SupplierStockTemplatePlatformMappingsComponent.vue').default);

Vue.component('supplier-shipping-countries-component', require('./components/admin/suppliershippingcountries/SupplierShippingCountriesComponent.vue').default);

//Manuelle Einkaufspreise eines Lieferantes des Types Hersteller
Vue.component('suppliermanualpurchasingpriceconfigs-component', require('./components/admin/suppliermanualpurchasingpriceconfig/SupplierManualPurchasingPriceConfigs.vue').default);

//Manuelle Einkaufspreise eines Lieferantes des Types Hersteller
Vue.component('suppliermanualpurchasingpriceartikelfilters-component', require('./components/admin/suppliermanualpurchasingpriceartikelfilters/SupplierManualPurchasingPriceArtikelFiltersComponent.vue').default);

//Gesammte Versandkosten der Versanddienstleister
Vue.component('shippingcosts-component', require('./components/admin/shippingcosts/ShippingCostsComponent.vue').default);

//Sperrartikels der eingegebenen Plattform
Vue.component('platformblockingartikels-component', require('./components/admin/platformblockingartikel/PlatformBlockingArtikelsComponent.vue').default);


//Wiederverkaufspreis der eingegebenen Plattform
Vue.component('platformresaleprices-component', require('./components/admin/platformresaleprices/PlatformResalePricesComponent.vue').default);
//Konfiguration der Lieferantenwiederkaufspreise der Plattformvorlage 14.06.2023
Vue.component('platformsupplierresalepriceconfig-component', require('./components/admin/platformsupplierresalepriceconfig/PlatformSupplierResalePriceConfigComponent.vue').default);

//Benachrictigungsteilnehmer
Vue.component('notificationsubscribers-component', require('./components/admin/notificationsubscribers/NotificationSubscribersComponent.vue').default);

//Manuelle Einkaufspreis der Lieferantenartikels
Vue.component('suppliermanualpurchasingprices-component', require('./components/admin/suppliermanualpurchasingprices/SupplierManualPurchasingPricesComponent.vue').default);

// Übersicht der Verpackungskosten 
Vue.component('packagingcosts-component', require('./components/admin/packagingcosts/PackagingCostsComponent.vue').default);

//Verpackungsgewicht, das Gewicht der Verpackungsmaterialien
Vue.component('packagingweight-component', require('./components/admin/packagingweight/PackagingWeightComponent.vue').default);

//Lieferantenbestände
Vue.component('supplierstocks-component', require('./components/admin/supplierstock/SupplierStockComponent.vue').default);

// Plattformenbestände
Vue.component('platformsupplieroffers-component', require('./components/admin/platformsupplieroffers/PlatformSupplierOffersComponent.vue').default);

// Ignorierte Lieferanten einer Plattform 10.10.2023
Vue.component('platformsupplierignores-component', require('./components/admin/platformsupplierignore/PlatformSupplierIgnoresComponent.vue').default);

// Plattformexportfilefilter 
Vue.component('platformexportfilter-component', require('./components/admin/platformexportfilter/PlatformExportFilterComponent.vue').default);

// 
Vue.component('ownofferrandomstocks-component', require('./components/admin/ownofferrandomstock/OwnOfferRandomStocksComponent.vue').default);

Vue.component('export-csv-component', require('./components/ExportCsvButton.vue').default);

// ShippingChargesFileMovingTaskComponent
Vue.component('shippingchargesfilemovingtask-component', require('./components/admin/shippingchargesfilemovingtask/ShippingChargesFileMovingTaskComponent.vue').default);

Vue.component('dummyplatformownoffers-component', require('./components/admin/dummyplatformownoffer/DummyPlatformOwnofferComponent.vue').default);

Vue.component('dummyplatformownofferstable-component', require('./components/admin/dummyplatformownoffer/DummyPlatformOwnOffersTableComponent.vue').default);

Vue.component('supplierstockshippingsurcharge-component', require('./components/admin/supplierstockshippingsurcharges/SupplierStockShippingSurchargeComponent.vue').default);

Vue.component('comparescheduledshippingservicecharges-component', require('./components/admin/scheduledshippingservicecharges/ScheduledShippingServiceChargesComponent.vue').default);

// Artikeltypes
Vue.component('artikeltypes-component', require('./components/admin/artikeltype/ArtikelTypeComponent.vue').default);

// Artikeltypegroups
Vue.component('artikeltypegroup-component', require('./components/admin/artikeltypegroup/ArtikelTypeGroupComponent.vue').default);

// Zeitpläne der Jobs
Vue.component('schedulejobsconfigs-component', require('./components/admin/schedulejobsconfig/ScheduleJobsConfigsComponent.vue').default);

// Blockierung der Lieferantenbestände
Vue.component('supplierstocksblocked-component', require('./components/admin/supplierstocksblocked/SupplierStocksBlockedComponent.vue').default);

// Manuelle Angebotenexport der Plattformen 
Vue.component('ownoffersmanualexport-component', require('./components/admin/ownoffersmanualexport/OwnoffersManualExports.vue').default);

// Plattformzuordnung des manuellen Angebotenexports 
Vue.component('ownoffersmanualexportplatforms-component', require('./components/admin/ownoffersmanualexportplatforms/OwnOffersManualExportPlatforms.vue').default);

// Benutzer
Vue.component('users-component', require('./components/admin/users/UsersComponent.vue').default);

// Aktivitäten der Benutzer
Vue.component('useractivities-component', require('./components/admin/useractivity/UserActivitiesComponent.vue').default);

// Übersicht der Marktplätze
Vue.component('marketplace-component', require('./components/admin/marketplaces/MarketplacesComponent.vue').default);

// Übersicht Marktplatzkonfigurationen eines Marktplatzes.
Vue.component('marketplaceconfig-component', require('./components/admin/marketplaceconfigs/MarketplaceConfigsComponent.vue').default);

// Übersicht der Kategorienzuordnung eines Marktplatzes
Vue.component('marketplace-categories-mapping-component', require('./components/admin/marketplacecategoriesmappings/MarketplaceCategoriesMappingComponent.vue').default);

// Übersicht der Bestpreisabschläge einer Plattformvorlage
Vue.component('add_platformtemplate_bestpricemarkdown-component', require('./components/admin/platformbestpricemarkdowns/PlatformBestpriceMarkdowns.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#wrapper',
    vuetify,

});

