<template>
    <div class="vue-csv-uploader">
        <v-app>
            <v-form ref="form">
                <v-row>
                    <v-col cols="12" sm="6" md="3">
                        <v-text-field v-model="platformName" label="Platform" disabled></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                        <v-text-field v-model="platformtemplateName" label="Vorlagename" disabled></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                        <v-text-field v-model="platformtemplateCountry" label="Land" disabled></v-text-field>
                    </v-col>
                </v-row>
                <div class="table-responsive">
                    <!-- https://smarttutorials.net/dynamically-add-or-remove-table-row-using-vuejs/ -->
                    <button type='button' class="btn btn-danger" @click="addNewRow">
                        <i class="fas fa-plus-circle"></i>
                        Bestpreisabschlag hinzufügen
                    </button>

                    <table class="table" ref="markdowntable">
                        <thead>
                            <tr>
                                <th scope="col">ARWG von</th>
                                <th scope="col">ARWG bis</th>
                                <th scope="col">ARAFPK</th>
                                <th scope="col text-right">Preisabschlag in €</th>
                                <th scope="col">Beschreibung</th>
                                <th scope="col">#</th>
                            </tr>
                        </thead>
                        <tr v-for="(markdown, k) in bestpricemarkdowns" :key="k">
                            <td>
                                <v-text-field hide-details="auto" type="number" min="1" step="1"
                                    v-model="markdown.arwg_from" required></v-text-field>
                            </td>
                            <td>
                                <v-text-field hide-details="auto" type="number" min="1" step="1"
                                    v-model="markdown.arwg_to" required></v-text-field>
                            </td>
                            <td>
                                <v-text-field hide-details="auto" type="number" min="1" step="1"
                                    v-model="markdown.arafpk" :rules="[rules.required]" required></v-text-field>
                            </td>
                            <td>
                                <v-text-field hide-details="auto" type="number" min="0" step="0.01"
                                    v-model="markdown.pricemarkdown" :rules="[rules.required, rules.price]"
                                    required></v-text-field>
                            </td>
                            <td>
                                <v-text-field hide-details="auto" v-model="markdown.description"></v-text-field>
                            </td>
                            <td scope="row" class="trashIconContainer">
                                <i class="far fa-trash-alt fa-2x" style="color: red;"
                                    @click="deleteRow(k, markdown)"></i>
                            </td>
                        </tr>
                    </table>
                </div>
                <v-btn-toggle borderless>
                    <v-btn value="left" @click="back">
                        Zurück
                    </v-btn>
                    <slot name="submit" :submit="submit">
                        <v-btn color="success" @click="submit">
                            Speichern
                        </v-btn>
                    </slot>
                </v-btn-toggle>
            </v-form>
        </v-app>
    </div>
</template>

<script>
import axios from 'axios';
export default {
    props: {
        value: Array,
        editdata: null,
        url: {
            type: String,
            default: '/admin/platformbestpricemarkdowns'
        },

        callback: {
            type: Function,
            default: () => ({})
        },
        catch: {
            type: Function,
            default: () => ({})
        },
        finally: {
            type: Function,
            default: () => ({})
        },
        headers: {
            default: null
        },
        submitBtnText: {
            type: String,
            default: "Speichern"
        },
        buttonClass: {
            type: String,
            default: "btn btn-primary"
        },
        inputClass: {
            type: String,
            default: "form-control-file"
        },
        validation: {
            type: Boolean,
            default: true,
        },
        tableSelectClass: {
            type: String,
            default: 'form-control'
        },
    },

    data: () => ({
        rules: {
            required: value => !!value || 'Erforderlich',
            counter: value => value.length <= 20 || 'Max 20 characters',
            price: value => {
                return /^\d+(\.\d+)*$/.test(value) || 'Ungültig'
            },
        },
        enableSubmit: false,
        platformtemplate_id: 0,
        platformName: '',
        platformtemplateName: '',
        platformtemplateCountry: '',
        bestpricemarkdowns: [],
        // bestpricemarkdowns: [{
        //     platformtemplate_id: this.platformtemplate_id,
        //     arwg_from: 0,
        //     arwg_to: 0,
        //     arafpk: 1,
        //     pricemarkdown: 0.00,
        //     description: '',
        //     lastAction: 1,
        // }],
    }),

    created() {
        this.bestpricemarkdowns = [];
        this.platformName = this.editdata.platform.name;
        this.platformtemplate_id = this.editdata.id;
        this.platformtemplateName = this.editdata.name;
        this.platformtemplateCountry = this.editdata.country;
        this.getPlatformtemplateBestpriceMarkdowns();
    },

    methods: {
        back: function () {
            window.location = location.protocol + "//" + location.hostname + "/admin/platforms/" + this.editdata.platforms_id + "/platformtemplates";
        },
        getPlatformtemplateBestpriceMarkdowns: function () {
            axios.get('/admin/getplatformtemplatebestpricemarkdowns/' + this.platformtemplate_id)
                .then(function (response) {
                    this.bestpricemarkdowns = response.data;
                }.bind(this));
        },
        addNewRow() {
            this.bestpricemarkdowns.unshift({
                id: 0,
                platformtemplate_id: this.platformtemplate_id,
                arwg_from: 0,
                arwg_to: 0,
                arafpk: 1,
                pricemarkdown: 0.00,
                description: '',
                lastAction: 1,
            });
        },
        deleteRow(index, markdown) {
            var idx = this.bestpricemarkdowns.indexOf(markdown);
            console.log(idx, index);
            if (idx > -1) {
                this.bestpricemarkdowns.splice(idx, 1);
                if (markdown.id > 0) {
                    axios.delete('/admin/platformbestpricemarkdowns/' + markdown.id
                    ).then(response => {
                        console.log(response);
                        this.showMsgBoxOne(response, false);
                    }).catch(response => {
                        _this.catch(response);
                    }).finally(response => {
                        _this.finally(response);
                    });
                }
            }
        },

        submit() {
            const _this = this;
            axios.post(this.url,
                this.bestpricemarkdowns,
            ).then(response => {
                this.showMsgBoxOne(response, true);
            }).catch(response => {
                _this.catch(response);
                console.log(response);
            }).finally(response => {
                _this.finally(response);
            });
        },
        showMsgBoxOne(callback, change) {
            if (callback.data.data.code > 200) {
                this.$bvModal.msgBoxOk(callback.data.data.message, {
                    title: 'FEHLER',
                    headerBgVariant: 'danger',
                    headerTextVariant: 'light',
                    titleVariant: 'danger',
                    //bodyBgVariant: 'warning',
                    bodyTextVariant: 'danger',
                    size: 'lg',
                    okVariant: 'warning'
                });
                return;
            }
            //this.$bvModal.msgBoxOk(callback.data)
            this.$bvModal.msgBoxOk(callback.data.data.message, {
                title: 'MELDUNG',
                headerBgVariant: 'success',
                headerTextVariant: 'light',
                titleVariant: 'success',
                //bodyBgVariant: 'warning',
                bodyTextVariant: 'success',
                size: 'lg',
                okVariant: 'success'
            })
                .then(value => {
                    if (change) {
                        window.location = location.protocol + "//" + location.hostname + "/admin/platformtemplatebestpricemarkdownsindex/" + this.platformtemplate_id;
                    }
                })
                .catch(err => {

                })
        },

        checkPrice(price) {
            return /^\d+(\.\d+)*$/.test(price);
        },
        makeErrorToast() {
            this.$bvToast.toast('Test', {
                title: 'Fehler'
            })
        },

        showError(mp) {
            if (mp.prozent == null) {
                this.$bvModal.msgBoxOk('Prozent darf nicht leer sein!' + mp, {
                    title: 'FEHLER',
                    headerBgVariant: 'danger',
                    headerTextVariant: 'light',
                    titleVariant: 'danger',
                    //bodyBgVariant: 'warning',
                    bodyTextVariant: 'danger',
                    size: 'lg',
                    okVariant: 'warning'
                });
            }
        },

        onClear(markdown) {
            return markdown;
        },

    },
    watch: {

    },
    computed: {
    },
};
</script>
