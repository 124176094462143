<template>
    <div class="row">
        <b-modal id="modal-prevent-closing" :header-bg-variant="headerBgVariant"
            :header-text-variant="headerTextVariant" ref="modal" :title="modalname" @show="resetModal"
            @hidden="resetModal" @ok="handleOk" ok-title-html="Speichern" cancel-title="Abbrechen"
            :ok-disabled="disableOk">
            <form ref="form" @submit.stop.prevent="handleSubmit">
                <b-form-group label="Plattform*" label-for="platformname-input" label-cols="8" label-cols-lg="4"
                    invalid-feedback="Plattform ist pflicht">
                    <b-form-input id="platformname-input" v-model="editdata.name" required disabled></b-form-input>
                </b-form-group>
                <b-form-group label="Lieferant*" label-for="supplier-input" label-cols="8" label-cols-lg="4">
                    <b-form-select id="supplier-input" v-model="supplierstockplatformdiscount.supplier_id"
                        :options="suppliersdata" value-field="id" text-field="supplierName" disabled-field="notEnabled"
                        :state="supplierState" invalid-feedback="Lieferant ist pflicht" required></b-form-select>
                </b-form-group>
                <b-form-group label="ARWG-Von*" label-for="arwg-from-input" label-cols="8" label-cols-lg="4"
                    invalid-feedback="ARWG-Von ist pflicht">
                    <b-form-input id="arwg-from-input" v-model="supplierstockplatformdiscount.ARWG_from"
                        :state="arwgFromState" type="number" min="1" value="1" step="1" pattern="^(?:[0-9]\d*|\d)$"
                        required></b-form-input>
                </b-form-group>
                <b-form-group label="ARWG-bis*" label-for="arwg-to-input" label-cols="8" label-cols-lg="4"
                    invalid-feedback="ARWG-bis ist pflicht">
                    <b-form-input id="arwg-to-input" v-model="supplierstockplatformdiscount.ARWG_to"
                        :state="arwgToState" type="number" min="1" value="1" step="1" pattern="[0-9]+([\\,|\\.][0-9]+)?"
                        required></b-form-input>
                </b-form-group>
                <b-form-group label="Rabatt*" label-for="platform_discount-input" label-cols="8" label-cols-lg="4"
                    invalid-feedback="Rabatt ist pflicht">
                    <b-form-input id="platform_discount-input" v-model="supplierstockplatformdiscount.platform_discount"
                        type="number" min="0" value="0" step="0.01" pattern="[0-9]+([\\,|\\.][0-9]+)?"
                        :state="platform_discountState" required></b-form-input>
                </b-form-group>
                <b-form-group label="Rabatt-Type*" label-for="platform_discount_type-input" label-cols="8"
                    label-cols-lg="4" invalid-feedback="Rabatt-Type ist pflicht">
                    <b-form-select id="platform_discount_type-input"
                        v-model="supplierstockplatformdiscount.platform_discount_type" :options="typeoptions"
                        value-field="key" text-field="type" disabled-field="notEnabled" required></b-form-select>
                </b-form-group>
                <b-form-group label="Gültig bis*" label-cols="8" label-cols-lg="4"
                    invalid-feedback="Gültigkeit ist pflicht!">
                    <b-form-datepicker id="datepicker-valid_until_date"
                        v-model="supplierstockplatformdiscount.valid_until_date" v-bind="locale_labels"
                        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" locale="de" required>
                    </b-form-datepicker>
                </b-form-group>
                <b-form-group label="Beschreibung" label-for="description-input" label-cols="8" label-cols-lg="4">
                    <b-form-input id="description-input"
                        v-model="supplierstockplatformdiscount.description"></b-form-input>
                </b-form-group>
                <b-form-group label="Status*" label-for="lastaction-checkbox" label-cols="8" label-cols-lg="4">
                    <b-form-checkbox id="lastaction-checkbox" v-model="supplierstockplatformdiscount.LastAction"
                        name="checkbox-1" value="1" unchecked-value="0">
                        {{ supplierstockplatformdiscount.LastAction > 0 ? "Aktiviert" : "Deaktiviert" }}
                    </b-form-checkbox>

                </b-form-group>
            </form>
        </b-modal>
        <div class="table-responsive">
            <table class="table table-bordered table-striped supplierstockplatformdiscount-table" width="100%"
                cellspacing="0">
                <thead>
                    <tr>
                        <th>id</th>
                        <th>Lagerbestand</th>
                        <th>ARWG-Von</th>
                        <th>ARWG-Bis</th>
                        <th>Rabatt</th>
                        <th>Gültig bis</th>
                        <th>Beschreibung</th>
                        <th>Stand</th>
                        <th>Aktionen</th>
                    </tr>
                </thead>
                <tbody>
                </tbody>
            </table>
        </div>
        <div class="row">
            <b-button href='#' variant='outline-primary' v-on:click="back">Zurück</b-button>
        </div>
    </div>

</template>

<script>
    import axios from 'axios';
  
    export default {
        props: {
            value: Array,
            editdata: null,
            suppliersdata:null,
            url: {
                type: String,
                default: '/admin/supplierstockplatformdiscounts'
            },
            location_url: {
                type: String,
                default: '/admin/supplierstockplatformdiscount/'
            },
            callback: {
                type: Function,
                default: () => ({})
            },
            catch: {
                type: Function,
                default: () => ({})
            },
            finally: {
                type: Function,
                default: () => ({})
            },
            headers: {
                default: null
            },
            submitBtnText: {
                type: String,
                default: "Speichern"
            },
            buttonClass: {
                type: String,
                default: "btn btn-primary"
            },
            inputClass: {
                type: String,
                default: "form-control-file"
            },
            validation: {
                type: Boolean,
                default: true,
            },

            tableSelectClass: {
                type: String,
                default: 'form-control'
            },
        },

        data: () => ({
            modalname:"Rabatt hinzufügen",
            headerBgVariant: 'success',
            headerTextVariant: 'light',
            disableOk:false,
            supplierstocktemplates:{},
            onlynumberspattern : /^[0-9]*$/,
            supplierstockplatformdiscount: {
                id: 0,
                platform_id:0,
                supplier_id: 0,
                ARWG_from: null,
                ARWG_to: null,    
                platform_discount: null,
                platform_discount_type: 'Preis',
                valid_until_date: null,
                description: null,
                LastAction: 1
            },

        locale_labels: {
            labelPrevDecade: 'Vorheriges Jahrzehnt',
            labelPrevYear: 'Vorheriges Jahr',
            labelPrevMonth: 'Vorheriger Monat',
            labelCurrentMonth: 'Aktueller Monat',
            labelNextMonth: 'Nächster Monat',
            labelNextYear: 'Nächstes Jahr',
            labelNextDecade: 'Nächstes Jahrzehnt',
            labelToday: 'Heute',
            labelSelected: 'Ausgewähltes Datum',
            labelNoDateSelected: 'Kein Datum gewählt',
            labelCalendar: 'Kalender',
            labelNav: 'Kalendernavigation',
            labelHelp: 'Mit den Pfeiltasten durch den Kalender navigieren'
            },
        }),

        created() {
            this.typeoptions = this.$func.getValueTypeOptions();
            this.supplierstockplatformdiscount.platform_id = this.editdata.id;
            this.getSupplierStockPlatformDiscounts('/admin/supplierstockplatformdiscount/getsupplierstockplatformdiscountsdata/'+ this.editdata.id);
        },
        mounted() {
             window.supplierstockplatformdiscounts=this;
        },
        methods: {
            back: function(){
                window.location = location.protocol + "//" + location.hostname + "/admin/platforms/";
            },
            async getSupplierStockPlatformDiscounts(address) {
                 $(document).ready(function() {
                    var table = $('.supplierstockplatformdiscount-table').DataTable({
                        processing: false,
                        serverSide: true,
                        searching: true,
                        searchable:true,
                        responsive: true,
                        select: true,
                        pageLength: 50,
                        language: {
                            url: 'https://cdn.datatables.net/plug-ins/1.10.21/i18n/German.json'
                        },
                        ajax: address,
                        columns: [
                            {data: 'id', name: 'id'}, 
                            {data: 'supplier', name: 'supplier'},
                            {data: 'ARWG_from', name: 'ARWG_from'},
                            {data: 'ARWG_to', name: 'ARWG_to'},
                            {data: 'platform_discount', name: 'platform_discount'},
                            { data: 'valid_until_date', name: 'valid_until_date' },
                            {data: 'description', name: 'description'},
                            {data: 'status', name: 'status'},
                            {data: 'action', name: 'action'},
                        ],
                    });
                });
            },

            getSupplierStockTemplates: function(){
                axios.get('/admin/getallsupplierstocktemplates')
                .then(function (response) {
                    this.supplierstocktemplates = response.data;
                }.bind(this));
            },

            getEditSupplierStockPlatformDiscount(edit_id){
                axios.get(this.url+'/'+edit_id+'/edit')
                .then(function (response) { 
                    this.$bvModal.show('modal-prevent-closing');
                    this.modalname = "Lieferantenrabatt bearbeiten"
                    this.supplierstockplatformdiscount = response.data;
                    this.headerBgVariant = 'primary';
                }.bind(this));
            },
            showMsgBoxOne(callback, change) {
                this.$bvModal.msgBoxOk(callback.data)
                .then(value => {
                   if(change)
                    {
                        $('.supplierstockplatformdiscount-table').DataTable().ajax.reload();
                    }
                })
                .catch(err => {
                    // An error occurred
                })
            },

            getDeleteSupplierStockPlatformDiscount(edit_id){
                const _this = this;
                this.$bvModal.msgBoxConfirm('Möchten Sie diesen Lieferantenrabatt wirklich löschen?', {
                title: 'WARNUNG',
                headerVariant: "warning",
                size: 'md',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'JA',
                cancelTitle: 'NEIN',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
                })
                .then(value => {
                    if(value){
                        axios.delete(this.url+'/'+edit_id
                        ).then(response => {
                            this.showMsgBoxOne(response, true);
                        }).catch(response => {
                            _this.catch(response);
                        }).finally(response => {
                            _this.finally(response);
                        });
                        }
                })
                .catch(err => {
                    // An error occurred
                })
            },

      checkFormValidity() {
        const valid = this.$refs.form.checkValidity()
        return valid
      },
      resetModal() {
        this.headerBgVariant = 'success';
        this.modalname = "Neuen Lieferantenrabatt hinzufügen";
        this.supplierstockplatformdiscount.id = 0;
        this.supplierstockplatformdiscount.platform_id = this.editdata.id;
        this.supplierstockplatformdiscount.supplier_id = 0;
        this.supplierstockplatformdiscount.ARWG_from = null;
        this.supplierstockplatformdiscount.ARWG_to = null;
        this.supplierstockplatformdiscount.platform_discount = null;
        this.supplierstockplatformdiscount.platform_discount_type = 'Preis';
        this.supplierstockplatformdiscount.valid_until_date = null;
        this.supplierstockplatformdiscount.description = null;
        this.supplierstockplatformdiscount.LastAction = 1;
      },
      handleOk(bvModalEvent) {
        bvModalEvent.preventDefault()
        this.handleSubmit()
      },
      handleSubmit() {
         const _this = this;
        if(this.supplierstockplatformdiscount.id > 0){
            axios.put(this.url+'/'+this.supplierstockplatformdiscount.id,
                this.supplierstockplatformdiscount,
            ).then(response => {
                this.showMsgBoxOne(response, true);
            }).catch(response => {
                _this.catch(response);
            }).finally(response => {
                _this.finally(response);
            });
        }else{
            axios.post(this.url,
                this.supplierstockplatformdiscount,
            ).then(response => {
                this.showMsgBoxOne(response, true);
            }).catch(response => {
                _this.catch(response);
            }).finally(response => {
                _this.finally(response);
            });
        }
        this.$nextTick(() => {
            this.$bvModal.hide('modal-prevent-closing')
        })
      }
        },
        watch: {

        },
        computed: {
            supplierState(){
                return this.supplierstockplatformdiscount.supplier_id == 0 ? false : true;
            },
            arwgFromState() {
                return this.supplierstockplatformdiscount.ARWG_from == null ? false : true;
            },

            arwgToState() {
                return this.supplierstockplatformdiscount.ARWG_to == null ? false : true;
            },
            platform_discountState(){
                return (this.supplierstockplatformdiscount.platform_discount == null || this.supplierstockplatformdiscount.platform_discount == "") ? false : true;;
            }
        },
    };
</script>
