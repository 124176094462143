<template>
    <v-app>
        <v-data-table :headers="headers" :items="useractivities" :footer-props="{
            showFirstLastPage: true,
            showCurrentPage: true,
            itemsPerPageOptions: [50, 100, 150, -1],
            itemsPerPageAllText: 'Alle',
            itemsPerPageText: 'Datensätze pro Seite',
            pageText: '{0}-{1} von {2}'
        }">
            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title>Aktivitäten der Benutzer</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <v-dialog v-model="dialog" max-width="900px">
                        <v-card>
                            <v-app-bar dark color="success">
                                <v-card-title>
                                    <span class="text-h5">{{ formTitle }}</span>
                                </v-card-title>
                            </v-app-bar>
                            <v-card-text>
                                <v-container v-if="editedItem !== null">
                                    <v-row no-gutters>
                                        <v-col cols="12">
                                            <v-text-field v-model="editedItem.causer['name']" readonly
                                                label="Benutzer"></v-text-field>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-text-field v-model="editedItem.description" readonly
                                                label="Aktivität"></v-text-field>
                                        </v-col>
                                        <v-divider></v-divider>
                                        <v-simple-table fixed-header height="300px">
                                            <template v-slot:default>
                                                <thead>
                                                    <tr>
                                                        <th class="text-left" style="min-width: 300px !important;">
                                                            Spalte
                                                        </th>
                                                        <th class="text-left" style="min-width: 300px !important;">
                                                            Alter Wert
                                                        </th>
                                                        <th class="text-left" style="min-width: 300px !important;">
                                                            Neuer Wert
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="[key, value] in Object.entries(editedItem.properties['attributes'])">
                                                        <td v-if="key !== 'created_at' && key !== 'updated_at'">
                                                            {{ key }}
                                                        </td>
                                                        <td v-if="key !== 'created_at' && key !== 'updated_at'">
                                                            {{ (editedItem.properties['old'] === undefined) ? '' : editedItem.properties['old'][key] }}
                                                        </td>
                                                        <td v-if="key !== 'created_at' && key !== 'updated_at'">
                                                            {{ value }}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </template>
                                        </v-simple-table>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="error" text @click="close">
                                    Schließen
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-toolbar>
            </template>
            <template v-slot:item.causer="{ item }">
                {{ item.causer['name'] }}
            </template>
            <template v-slot:item.created_at="{ item }">
                {{ formatDate(item.created_at) }}
            </template>
            <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon class="mr-2" color="blue" v-bind="attrs" v-on="on" @click="showItem(item)">
                            mdi-eye
                        </v-icon>
                    </template>
                    <span>Anzeigen</span>
                </v-tooltip>
            </template>
        </v-data-table>
    </v-app>
</template>
<script>
export default {
    props: {
        value: Array,
        url: {
            type: String,
            default: '/admin/useractivities'
        },
        callback: {
            type: Function,
            default: () => ({})
        },
        catch: {
            type: Function,
            default: () => ({})
        },
        finally: {
            type: Function,
            default: () => ({})
        },
    },

    data: () => ({
        messagedialog: false,
        dialog: false,

        headers: [
            {
                text: 'Id',
                align: 'start',
                sortable: true,
                value: 'id',
            },
            { text: 'Benutzer', value: 'causer' },
            { text: 'Aktivität', value: 'description' }, 
            { text: 'Ausgeführt am', value: 'created_at' },
            { text: 'Aktionen', value: 'actions', sortable: false },
        ],
        menu1: false,
        success: false,
        useractivities: [],
        boxOne: '',
        editedItem: null
    }),

    computed: {
        formTitle() {
            return 'Aktivität des Benutzers'
        },
    },

    watch: {
        dialog(val) {
            val || this.close()
        },
        dialogDelete(val) {
            val || this.closeDelete()
        },
    },

    created() {
        this.getUserActivities();
    },

    methods: {
        async getUserActivities() {
            axios.get('/admin/getuseractivities')
                .then(function (response) {
                    this.useractivities = response.data;
                }.bind(this));

        },
        showItem(item) {
            axios.get(this.url + '/' + item.id)
                .then(function (response) {
                    this.editedItem = response.data;
                    this.dialog = true
                }.bind(this));
        },

        close() {
            this.dialog = false
            this.$nextTick(() => {
                // this.editedItem = this.defaultItem;
            })
        },

        showMsgBoxOne(callback, change) {
            this.$bvModal.msgBoxOk(callback.data)
                .then(value => {
                    if (change) {
                        this.$nextTick(() => {
                            // this.editedItem = this.defaultItem;
                        });
                        this.getUserActivities();
                    }
                })
                .catch(err => {
                })
        },

        formatDate(date) {
            try {
                if (!date) return null
                var d = new Date(date); 
                return d.toLocaleString('de-DE')
            } catch (error) {
                
            }
            return date;
        },
    },
}
</script>