<template>
    <div class="row">
        <b-modal
            id="modal-prevent-closing"
            :header-bg-variant="headerBgVariant"
            :header-text-variant="headerTextVariant"
            ref="modal"
            :title="modalname"
            @show="resetModal"
            @hidden="resetModal"
            @ok="handleOk"
            ok-title-html = "Speichern"
            cancel-title = "Abbrechen"
            :ok-disabled="disableOk"
        >
            <form ref="form" @submit.stop.prevent="handleSubmit">

                <b-form-group
                label="Lagerbestand"
                label-for="supllierstockname-input"
                label-cols="8"
                label-cols-lg="4" 
                invalid-feedback="Lagerbestand ist pflicht" 
                >
                    <b-form-input
                        id="supllierstockname-input"
                        v-model="editdata.templateName" 
                        required
                        disabled
                    ></b-form-input>
                </b-form-group>
                <b-form-group
                label="Hersteller"
                label-for="hersteller-input"
                label-cols="8"
                label-cols-lg="4" 
                >
                    <b-form-select
                    id="hersteller-input"
                    v-model="supplierstockpricemarkdown.hersteller_number"
                    :options="herstellers"
                    value-field="number"
                    text-field="name"
                    disabled-field="notEnabled"
                    ></b-form-select>
                </b-form-group>
                <b-form-group
                label="ARWG-Von"
                label-for="arwg-from-input"
                label-cols="8"
                label-cols-lg="4" 
                invalid-feedback="ARWG-Von ist pflicht" 
                >
                   <b-form-input
                        id="arwg-from-input"
                        v-model="supplierstockpricemarkdown.productgroup_from" 
                        :state="productgroupFromState"
                        type="number"
                        min="0"
                        value="0"
                        step="1" 
                        pattern="^(?:[0-9]\d*|\d)$"
                        required
                    ></b-form-input>
                </b-form-group>
                <b-form-group
                label="ARWG-bis"
                label-for="arwg-bis-input"
                label-cols="8"
                label-cols-lg="4" 
                invalid-feedback="ARWG-bis ist pflicht" 
                >
                    <b-form-input
                        id="arwg-bis-input"
                        v-model="supplierstockpricemarkdown.productgroup_to" 
                        :state="productgroupToState"
                        type="number"
                        min="0"
                        value="0"
                        step="1" 
                        pattern="[0-9]+([\\,|\\.][0-9]+)?"
                        required
                    ></b-form-input>
                </b-form-group>
                <b-form-group
                label="Preisabschlag1"
                label-for="markdown1-bis-input"
                label-cols="8"
                label-cols-lg="4" 
                invalid-feedback="Preisabschlag1 ist pflicht" 
                >
                    <b-form-input
                        id="markdown1-input"
                        v-model="supplierstockpricemarkdown.markdown1" 
                        type="number"
                        min="0"
                        value="0"
                        step="0.01" 
                        pattern="[0-9]+([\\,|\\.][0-9]+)?"
                        :state="markdown1State"
                        required
                    ></b-form-input>
                </b-form-group>
                <b-form-group
                    label="Preisabschlag1-Type"
                    label-for="markdown1type-input"
                    label-cols="8"
                    label-cols-lg="4"
                    invalid-feedback="Abschlag1-Type ist pflicht" 
                >
                    <b-form-select
                    id="markdown1type-input"
                    v-model="supplierstockpricemarkdown.markdown1type"
                    :options="typeoptions"
                    value-field="key"
                    text-field="type"
                    disabled-field="notEnabled"
                    required
                    ></b-form-select>
                </b-form-group>
                <b-form-group
                label="Preisabschlag2"
                label-for="markdown2-bis-input"
                label-cols="8"
                label-cols-lg="4" 
                >
                    <b-form-input
                        id="markdown2-input"
                        v-model="supplierstockpricemarkdown.markdown2" 
                        type="number"
                        min="0"
                        value="0"
                        step="0.01" 
                        pattern="[0-9]+([\\,|\\.][0-9]+)?"
                    ></b-form-input>
                </b-form-group>
                <b-form-group
                    label="Preisabschlag2-Type"
                    label-for="markdown2type-input"
                    label-cols="8"
                    label-cols-lg="4"
                    invalid-feedback="Abschlag2-Type ist pflicht" 
                >
                    <b-form-select
                    id="markdown2type-input"
                    v-model="supplierstockpricemarkdown.markdown2type"
                    :options="typeoptions"
                    value-field="key"
                    text-field="type"
                    disabled-field="notEnabled"
                    ></b-form-select>
                </b-form-group>
                <b-form-group
                label="Beschreibung"
                label-for="description-input"
                label-cols="8"
                label-cols-lg="4"
                invalid-feedback="Name is required" 
                >
                <b-form-input
                    id="description-input"
                    v-model="supplierstockpricemarkdown.description" 
                ></b-form-input>
                </b-form-group>
            </form>
        </b-modal>
        <div class="table-responsive">
            <table class="table table-bordered table-striped supplierstockpricemarkdown-table"  width="100%" cellspacing="0">
                <thead>
                    <tr>
                        <th>id</th>
                        <th>Lagerbestand</th>
                        <th>Hersteller</th>
                        <th>ARWG-Von</th>
                        <th>ARWG-Bis</th>
                        <th>Abschlag1</th>
                        <th>Abschlag1-Type</th>
                        <th>Abschlag2</th>
                        <th>Abschlag2-Type</th>
                        <th>Beschreibung</th>
                        <th>Zustand</th>
                        <th>Aktionen</th>
                    </tr>
                </thead>
                <tbody>
                </tbody>
            </table>
        </div>
        <template>
            <v-row>
            <v-col cols="3">
                <v-btn elevation="2" rounded small v-on:click="back">Zurück</v-btn>
            </v-col>
            </v-row> 
        </template>
    </div>
</template>

<script>
    import axios from 'axios';
  
    export default {
        props: {
            value: Array,
            editdata: null,
            url: {
                type: String,
                default: '/admin/supplierstockpricemarkdowns'
            },
            location_url: {
                type: String,
                default: '/admin/supplierstockpricemarkdown/'
            },
            callback: {
                type: Function,
                default: () => ({})
            },
            catch: {
                type: Function,
                default: () => ({})
            },
            finally: {
                type: Function,
                default: () => ({})
            },
            headers: {
                default: null
            },
            submitBtnText: {
                type: String,
                default: "Speichern"
            },
            buttonClass: {
                type: String,
                default: "btn btn-primary"
            },
            inputClass: {
                type: String,
                default: "form-control-file"
            },
            validation: {
                type: Boolean,
                default: true,
            },

            tableSelectClass: {
                type: String,
                default: 'form-control'
            },
        },

        data: () => ({
            modalname:"Preisabschlag hinzufügen",
            headerBgVariant: 'success',
            headerTextVariant: 'light',
            disableOk:false,
            herstellers:{},
            wholesalers:{},
            supplierstocks:{},
            onlynumberspattern : /^[0-9]*$/,
            supplierstockpricemarkdown: {
                id: 0,
                supplierstocktemplate_id: 0,
                hersteller_number: null,
                productgroup_from: null,
                productgroup_to: null,
                markdown1: null,
                markdown1type: 'Preis',
                markdown2: null,
                markdown2type: 'Preis',
                description: null
            },
            // typeoptions:[ {key: 'Preis', type: 'Preis'},{key: 'Prozent', type: 'Prozent'},],
        }),

        created() {
            this.typeoptions = this.$func.getValueTypeOptions();
            this.supplierstockpricemarkdown.supplierstocktemplate_id = this.editdata.id;
            this.getHerstellers();
            this.getSupplierStockPricemarkdowns('/admin/supplierstockpricemarkdown/getsupplierstockpricemarkdowns/'+ this.editdata.id);
        },
        mounted() {
             window.supplierstockpricemarkdowns=this;
        },
        methods: {
            back: function(){
                window.location = location.protocol + "//" + location.hostname + "/admin/supplier/"+this.editdata.supplier_id +"/supplierstocktemplates";
            },
            async getSupplierStockPricemarkdowns(address) {
                 $(document).ready(function() {
                    var table = $('.supplierstockpricemarkdown-table').DataTable({
                        processing: false,
                        serverSide: true,
                        searching: true,
                        searchable:true,
                        responsive: true,
                        select: true,
                        pageLength: 50,
                        language: {
                            url: 'https://cdn.datatables.net/plug-ins/1.10.21/i18n/German.json'
                        },
                        ajax: address,
                        columns: [
                            {data: 'id', name: 'id'}, 
                            {data: 'supplierstocktemplatename', name: 'supplierstocktemplatename'},
                            {data: 'hersteller', name: 'hersteller'},
                            {data: 'productgroup_from', name: 'productgroup_from'},
                            {data: 'productgroup_to', name: 'productgroup_to'},
                            {data: 'markdown1', name: 'markdown1'},
                            {data: 'markdown1type', name: 'markdown1type'},
                            {data: 'markdown2', name: 'markdown2'},
                            {data: 'markdown2type', name: 'markdown2type'},
                            {data: 'description', name: 'description'},
                            {data: 'status', name: 'status'},
                            {data: 'action', name: 'action'},
                        ],
                    });
                });
            },

            getHerstellers: function(){
                axios.get('/admin/getallhersteller')
                .then(function (response) {
                    this.herstellers = response.data;
                }.bind(this));
            },

            getEditSupplierStockPricemarkdown(edit_id){
                axios.get('/admin/supplierstockpricemarkdowns/'+edit_id+'/edit')
                .then(function (response) { 
                    this.$bvModal.show('modal-prevent-closing');
                    this.modalname = "Preisabschlag des Lagerbestandes bearbeiten"
                    this.supplierstockpricemarkdown = response.data;
                    this.headerBgVariant = 'primary';
                }.bind(this));
            },
            showMsgBoxOne(callback, change) {
                this.$bvModal.msgBoxOk(callback.data)
                .then(value => {
                   if(change)
                    {
                       window.location = location.protocol + "//" + location.hostname + this.location_url + this.editdata.id;
                    }
                })
                .catch(err => {
                    // An error occurred
                })
            },

            getDeleteSupplierStockPricemarkdown(edit_id){
                const _this = this;
                this.$bvModal.msgBoxConfirm('Möchten Sie diesen Preisabschlag des Lagerbestandes wirklich löschen?', {
                title: 'WARNUNG',
                headerVariant: "warning",
                size: 'md',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'JA',
                cancelTitle: 'NEIN',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
                })
                .then(value => {
                    if(value){
                        axios.delete(this.url+'/'+edit_id
                        ).then(response => {
                            // _this.callback(response);
                            console.log(response);
                            this.showMsgBoxOne(response, true);
                        }).catch(response => {
                            _this.catch(response);
                        }).finally(response => {
                            _this.finally(response);
                        });
                        }
                })
                .catch(err => {
                    // An error occurred
                })
            },

      checkFormValidity() {
        const valid = this.$refs.form.checkValidity()
        return valid
      },
      resetModal() {
        this.headerBgVariant = 'success';
        this.modalname = "Neuen Preisabschlag des Lagerbestandes hinzufügen";
        this.supplierstockpricemarkdown.id = 0;
        this.supplierstockpricemarkdown.supplierstock_id = this.editdata.id;
        this.supplierstockpricemarkdown.hersteller_id = 0;
        this.supplierstockpricemarkdown.productgroup_from = null;
        this.supplierstockpricemarkdown.productgroup_to = null;
        this.supplierstockpricemarkdown.markdown1 = null;
        this.supplierstockpricemarkdown.markdown1type = 'Preis';
        this.supplierstockpricemarkdown.markdown2 = null;
        this.supplierstockpricemarkdown.markdown2type = 'Preis';
        this.supplierstockpricemarkdown.description = null;
      },
      handleOk(bvModalEvent) {
        bvModalEvent.preventDefault()
        this.handleSubmit()
      },
      handleSubmit() {
         const _this = this;
        if(this.supplierstockpricemarkdown.id > 0){
            axios.put(this.url+'/'+this.supplierstockpricemarkdown.id,
                this.supplierstockpricemarkdown,
            ).then(response => {
            // _this.callback(response);
            console.log(response);
            this.showMsgBoxOne(response, true);
            }).catch(response => {
                _this.catch(response);
            }).finally(response => {
                _this.finally(response);
            });
        }else{
            console.log(this.supplierstockpricemarkdown);
            axios.post(this.url,
                this.supplierstockpricemarkdown,
            ).then(response => {
                // _this.callback(response);
                console.log(response);
                this.showMsgBoxOne(response, true);
            }).catch(response => {
                _this.catch(response);
            }).finally(response => {
                _this.finally(response);
            });
        }

        // Hide the modal manually
        this.$nextTick(() => {
            this.resetModal();
            this.$bvModal.hide('modal-prevent-closing')
        })
      }
        },
        watch: {

        },
        computed: {
            platformtemplateState(){
                return this.supplierstockpricemarkdown.platformtemplate_id == 0 ? false : true;
            },
            productgroupFromState() {
                return this.supplierstockpricemarkdown.productgroup_from == null ? false : true;
            },

            productgroupToState() {
                return this.supplierstockpricemarkdown.productgroup_to == null ? false : true;
            },

            markdown1State(){
                return (this.supplierstockpricemarkdown.markdown1 == null || this.supplierstockpricemarkdown.markdown1 == "") ? false : true;;
            }
        },
    };
</script>
